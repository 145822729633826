@import "bootstrap-5.x/scss/_functions.scss";
@import "bootstrap-5.x/scss/_variables.scss";
@import "bootstrap-5.x/scss/bootstrap.scss";

.debug{
	&, & *{
		outline: solid 1px rgba(255,0,255,.2);
	}
}

:root{
	font-family: 'DM Sans', sans-serif;
}

html, body, #root{
	height: 100%;
}

svg.gap-logo{
	fill: #331434;
}

.Mui-error{
	// background-color: rgba(255, 0, 0, .1);

	svg{
		fill: rgba(255,0,0,.5);
	}
	
	input::placeholder {
		opacity: 1;
		color: rgba(255,0,0,.5);
	}
}


.disabled{
	opacity: .3;

	&, & *{
		pointer-events: none;
	}
}